@import './assets/css/global.scss';


body {
  margin: 0;
  font-family: $fontPoppin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: $fontCode;
}

#root {
  min-height: 100vh;
  font-family: $fontPoppin;
  font-size: $textDefault;
  color: $default !important;
  background: $backgroundApp;
}

.ant-layout {
  box-sizing: border-box;
  min-height: 100%;
  background: $backgroundApp;

  .box-container {
    .header-layout {
      background-color: $backgroundApp;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 3rem;
      padding: 0;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 35px;
        margin-top: 25px;
        width: 100%;

        .breadcrumbs{
          .ant-breadcrumb {
            font-size: 13px;

            ol {
              li {
                display: flex;
                align-items: center;
                span{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: .3rem;
                  svg{
                    font-size: 1rem;
                    position: relative;
                    bottom: 2px;
                  }
                }

                a {
                  display: flex;
                  align-items: center;
                }
              }
            }

            ol:last-child {
              display: flex;
              align-items: center;
            }
          }
        }

        .actions-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          min-width: 155px;
          gap: 6px;

          // button:hover,
          // button:focus {
          //   color: $primary !important;
          //   background-color: initial !important;
          // }

          .setting-btn,
          .notification-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #a8cee1;
            border: none;
            font-size: 0.9rem;
            min-width: 45px;
            height: 45px;

            .nav-icon {
              font-size: 1.3rem;
              color: white;//$primaryDark;
            }
          }
          .user-menu-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            font-size: 0.9rem;
          }

          .user-connected-box {
            display: flex;
            flex-direction: column;

            .user {
              display: flex;
              align-items: center;
              gap: 0.4rem;
              justify-content: center;
              min-height: 40px;
              font-size: 0.8rem;

              .icon {
                background-color: $secondary;
                padding: 0.4rem 0.5rem;
                border-radius: 50%;
                color: white;
                font-size: 0.8rem;
                min-width: 33px;
              }
            }
          }
        }

        .mobile-menu,
        .logo-mobile {
          display: none;
        }
      }

      .subheader-container {
        width: 100%;
        margin: 0 0rem;
        padding: 0;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .box-infos {
          display: flex;
          align-items: center;
          gap: 1rem;

          .breadcrumb-box {
            .ant-breadcrumb {
              font-size: 13px;

              ol {
                li {
                  display: flex;
                  align-items: center;
                  color: $subTitle;

                  a {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: $subTitle;
                    position: relative;
                    right: 1px;
                  }
                }
              }

              ol:last-child {
                display: flex;
                align-items: center;
              }
            }
          }

          .society-box {
            color: $primary;
            font-weight: 800;
          }
        }

      }
    }

    .online-detector {
      .ant-alert-content {
        .ant-alert-message {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .sider-nav {
    position: relative;
    max-width: 240px !important;
    width: 240px !important;
    background: $backgroundApp;

    .ant-layout-sider-children {
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      gap: 44px;
      background-color: $backgroundSidebar;
      min-height: 100vh;
      height: 100px;
      padding: 1rem 15px;
      border-radius: 0px 24px 0px 0px;

      .brand-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 20px 0;
        gap: 0.8rem;

        .logo {
          width: 100%;
          position: relative;
          top: .5rem;
        }

        .brand-text {
          font-family: "Lobster";
          color: $primary;
          font-size: 1.8rem;
        }
      }

      .brand-box.collapsed {
        margin: 15px 20px 9px;

        .logo {
          transition: 1ms;
          position: relative;
          left: 0px;
          width: 50px;
        }
      }

      .nav-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 11;
        position: relative;
        bottom: 0rem;
        background-color: $backgroundSidebar;

        .main-nav {
          border-right: none;

          .ant-menu-item, .ant-menu-submenu-title{
            color: #5688A7;
            transition: unset;
            &::after {
              position: unset;
            }
          }
          .ant-menu-item:active, .ant-menu-submenu-title:active{
            border-radius: 300px;
            background: $selectedNavBackground;
            font-family: $fontPoppinMedium;
            color: $primary;
          }

          .ant-menu-item {
            .ant-menu-title-content {
              a {
                font-size: 0.9rem;
                color: #5688A7;
              }
            }

            .nav-icon {
              font-size: 1.3rem;
              position: relative;
              right: 2px;
            }
          }
          
          
          .ant-menu-item-selected {
            background: $selectedNavBackground;
            border-radius: 300px;
            font-family: $fontPoppinMedium;
            font-size: $textDefault;
            color: $primary;

            .ant-menu-title-content {
              a {
                // color: white;
                color: $primary;
              }
            }

            .nav-icon {
              color: $primary;
            }

            &::after {
              position: unset;
            }
          }

          .ant-menu-item:hover {
            .ant-menu-title-content {
              a {
                color: $primary;
              }
            }

            .nav-icon {
              color: $primary;
            }
          }

          .ant-menu-item-selected:hover {
            background-color: $selectedNavBackground;

            .ant-menu-title-content {
              a {
                color: #5688A7;
              }
            }

            .nav-icon {
              color: $primary;
            }

            &::after {
              border-right: 3px solid $primary;
            }
          }
        }

        .main-nav.collapsed {
          .ant-menu-item-selected {
            display: flex;
            align-items: center;

            .nav-icon {
              min-width: 19px;
            }
          }
        }

        .spacer {
          flex-grow: 1;
        }

        .user-connected-box {
          display: flex;
          flex-direction: column;

          .user {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            justify-content: center;
            min-height: 40px;
            font-size: 0.8rem;

            .icon {
              background-color: $primary;
              padding: 0.4rem 0.5rem;
              border-radius: 50%;
              color: white;
              font-size: 0.8rem;
            }

            .name {
              min-width: 120px;
            }
          }
        }
      }
    }
  }

  .sider-nav.collapsed-out {
    position: relative;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
    background: $backgroundApp;
  }
  .content-layout {
    .layout-background {
      // margin: 3rem 3rem;
      padding: 3rem 3rem;
      min-height: 280px;
      max-width: 1563px;
    }
  }
}

.year-select .ant-select-selection-item {
  font-size: 18px !important; 
}

.year-select {
  display: flex;
  align-items: center;
  justify-content: center; /* Si besoin d'un centrage horizontal */
  height: 100%; /* Assurez-vous que la div parent a une hauteur définie */
}

.year-select .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 50px !important; /* Ajuste la hauteur pour éviter le dépassement */
  padding-left: 24px !important; /* padding début */
  padding-right: 34px !important; /* padding fin (comprend la flèche) */
}

.year-select .ant-select-arrow {
  right: 24px !important; /* Ajoute un espace entre le texte et la flèche */
  top: 20px !important; /* Ajoute un espace entre la flèche et le haut */
  color: inherit !important; /* Adapte la couleur de la flèche au texte */
  font-size: 14px !important; /* Augmente la taille de la flèche */
}

.year-select-dropdown .ant-select-item {
  padding-left: 24px !important; /* Centre les options */
}

.ant-card {
  .ant-card-head-title {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5rem 0;
    text-overflow: ellipsis;
  }
}

.ant-avatar.bg--yellow{
  background-color: $yellow;
  color: $primary;
  font-weight: bold;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom, .ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft, .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight{
  padding-top: 0;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow, .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow, .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow{
  top: -6.585786px;
}

.ant-progress-bg{
  height: 12px !important;
}

@media screen and (min-width: 1742px) {
  .ant-layout {

    .box-container {
      display: flex;
      align-items: center;
      margin: 0rem 3rem;
      justify-content: center;
      max-width: 1563px;
      align-self: center;
      width: 100%;

      .header-layout {
        width: 100%;
      }
    }

    .ant-layout.content-layout {
      justify-content: center;
    }

  }

  .ant-layout .content-layout .layout-background {
    padding: 3rem .7rem;
    min-height: 280px;
    max-width: 1563px;
    align-self: center;
    width: 100%;
  }
}

@media screen and (max-width: 914px) {
  .ant-layout {
    font-size: 1rem;

    .sider-nav {
      display: none;
    }

    .box-container {
      .header-layout {
        background: $backgroundApp;
        height: 35px;
        padding: 0 0rem;
        margin: 0 1.5rem;

        .container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 35px;
          min-width: 100%;

          .search-bar {
            display: none;
          }

          .mobile-menu,
          .logo-mobile {
            display: flex;

            .icon {
              font-size: 1.5rem;
            }
          }

          .mobile-menu {
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 0.9rem;

            .mobile-menu-btn {
              height: 35px;

              .nav-icon {
                font-size: 1.2rem;
              }
            }
          }

          .logo-mobile {
            background-image: url("./assets/media/logo/logo_lianeli_fond_bleu.png");
            background-color: white;
            background-size: contain;
            background-repeat: no-repeat;
            flex-grow: 1;
            height: 30px;
            width: 100px;
            max-height: 30px;
          }
        }

        .subheader-container {
          width: 100%;
          margin: 0 2rem;
          padding: 0;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .box-infos {
            display: flex;
            align-items: center;
            gap: 1rem;

            .breadcrumb-box {
              .ant-breadcrumb {
                font-size: 13px;

                ol {

                  li {
                    display: flex;
                    align-items: center;

                    a {
                      display: flex;
                      align-items: center;
                    }
                  }
                }

                ol:last-child {
                  display: flex;
                  align-items: center;
                }
              }
            }

            .society-box {
              color: $primary;
              font-weight: 800;
              font-size: .8rem;
            }
          }

          .day-box {
            display: none;

            .day-month {
              max-height: 35px;
              display: flex;
              align-items: center;
              flex-direction: row;
              gap: 1rem 0;

              .day,
              .month {
                display: none;
              }

              padding-right: .5rem;
              border-right: 1px solid #ddd;
            }

            .hours {
              padding-left: .5rem;
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    .content-layout {
      .layout-background {
        border: none;
        margin: 37px 0;
        padding: 40px 25px;
        min-height: 280px;
      }
    }
  }

  .sidebar-mobile-menu {
    .ant-drawer-content-wrapper {
      max-width: 265px;

      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-body {
            .brand-box {
              display: flex;
              align-items: center;
              gap: .5rem;
              padding-left: 20px;

              .logo-mobile {
                width: 120px;
              }

              .brand-mobile {
                font-size: 2rem;
                font-family: "Lobster";
                color: $primary;
              }
            }

            .ant-divider {
              margin: 1rem 0;
            }

            ul {
              list-style: none;
              padding-left: 20px;

              li {
                padding: 15px 0;
                font-size: $textMobile;
                display: flex;
                align-items: center;
                gap: .5rem;
                color: $primary;

                .nav-icon {
                  font-size: 1.3rem;
                }
              }

              .divider {
                margin: 10px 0;
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 374px) {
  .ant-layout .box-container .header-layout .subheader-container .box-infos .society-box {
    display: none;
  }
}